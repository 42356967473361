#preload-block {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
}

#preload-block .square-block {
    background: url('../images/Gear.gif') no-repeat center center;
    width: 80px;
    height: 80px;
    text-align: center;
    position: relative;
    top: 50%;
    margin: 0 auto;
}